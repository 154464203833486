(function () {
  'use strict';

  class DiscussionsViewCtrl {
    constructor($stateParams) {
      let vm = this;
      vm.$stateParams = $stateParams;
      vm.ctrlName = 'DiscussionsViewCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name discussions.view.controller:DiscussionsViewCtrl
   *
   * @description
   *
   */
  angular
    .module('discussions.view')
    .controller('DiscussionsViewCtrl', DiscussionsViewCtrl);
}());
